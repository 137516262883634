@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.singleSelect {
	@include padding(padding, 0.5rem 1.25rem);
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.settings {
	.choice {
		@extend .title-m;
		font-weight: 600;
	}

	svg {
		width: 1.5rem;
		height: 1.5rem;
	}

	.flex {
		width: 100%;

		&:hover .deleteButton {
			opacity: 1;
		}
	}

	.addContainer {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.offset {
			transform: translateY(3rem);
		}
	}

	.deleteButton {
		position: absolute;
		right: 0.5rem;
		top: 50%;
		transform: translateY(-50%);
		background: none;
		border: none;
		cursor: pointer;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;

		svg {
			width: 1rem;
			height: 1rem;
		}
	}

	.withFlag {
		right: 2.5rem;
	}
}

.noGap {
	gap: 0;
}

.textarea {
	@include padding(padding, 0.7rem 2.75rem 0.7rem 2.5rem);
	@extend .button;
	display: flex;
	width: 100%;
	align-items: center;
	gap: 1rem;
	outline: 1px solid $border-color;

	border-radius: 0.5rem;
	border: none;
	background: $bg-grey;
	color: $text-placeholder;

	resize: none;
	* &:focus {
		outline: 2px solid $button-primary;
		border-radius: 0.5rem;
	}

	&:hover {
		background: $state-hover;
	}
}

.lateralPannelSection {
	@include padding(padding, 1rem 1.25rem);
	cursor: pointer;
	border-top: 1px solid $border-color;
	.button {
		font-weight: 600;
	}
}
