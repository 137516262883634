@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.navButtons {
	display: flex;
	margin: 1rem 1rem 0 1rem;
	border-bottom: 1px solid $border-color;

	button {
		all: unset;
		@include padding(padding, 0.5rem);
		font-size: 0.875rem; // 14px
		font-weight: 500;
		line-height: 1.25rem; // 20px
		letter-spacing: 0;
		color: $text-secondary;
		border-radius: 0.5rem 0.5rem 0 0;
		cursor: pointer;

		&:hover {
			// background: $state-hover;
			color: $text-primary;
		}
	}

	.selected {
		border-bottom: 1px solid $text-primary;
		color: $text-primary;
		font-weight: 600;
	}
}

.draggableNavigation {
	min-width: 22.5rem;
	max-width: 22.5rem;
	width: 22.5rem;
	display: flex;
	flex-direction: column;
	height: 100%;
	background: $bg-white;
	border-right: 1px solid $border-color;

	.navigationHeader {
		@include padding(padding, 1rem);
		display: flex;
		margin: 1rem;
		border-radius: 0.5rem;
		gap: 1rem;
		cursor: pointer;

		.lastEdit {
			@extend .button;
			color: $text-secondary;
			display: flex;
			align-items: center;
			gap: 0.5rem;
		}

		&:hover {
			background: $state-hover;
		}

		img {
			width: 2.5rem;
			height: 2.5rem;
			object-fit: cover;
			border-radius: 0.25rem;
		}
	}

	.navigationAddChapter {
		@include padding(padding, 0.5rem 1rem);
		border-top: 1px solid $border-color;
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {
			@extend .body;
			font-weight: 600;
			color: $text-primary;
		}

		.addChapterIcon {
			display: flex;
			align-items: center;
			justify-content: center;

			@include padding(padding, 0.5rem);
			width: 2.25rem;
			height: 2.25rem;
			min-width: 2.25rem;
			min-height: 2.25rem;
			border-radius: 0.5rem;
			cursor: pointer;

			&:hover {
				background: $state-hover;
			}
		}
	}

	.navigationContent {
		@include padding(padding-top, 0.5rem);
		height: 100%;
		width: 100%;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		overflow: hidden;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			display: none;
		}

		.question {
			color: $text-secondary;
		}

		.element {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: $bg-white;
			width: 100%;
			cursor: pointer;

			.title {
				@extend .button;
				min-height: 2.5rem;
				max-width: 9.5rem;
				padding: 0.75rem 0 0.75rem 2.5rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 0.25rem;
				word-break: break-word;
			}

			.placeholder {
				color: $text-placeholder;
			}

			p {
				margin: 0;
			}

			svg {
				@include padding(padding, 0.25rem);
				width: 1.75rem;
				height: 1.75rem;
				min-width: 1.75rem;
				min-height: 1.75rem;
				margin-right: 0.5rem;
				border-radius: 0.5rem;
				cursor: pointer;

				&:hover {
					background: $state-hover-selected;
				}
			}
		}

		.chapter {
			margin: 0.25rem 0.75rem;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-radius: 0.5rem;

			.title {
				@extend .button;
				color: $text-secondary;
				font-weight: 600;
				gap: 0;

				.subtitle {
					@extend .tabBarItems;
					color: $text-secondary;
				}
			}

			.icons {
				display: none;

				.icon {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			&:hover,
			&.selected {
				background: $state-hover;

				.icons {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					gap: 0.5rem;
					margin-right: 0.75rem;

					svg {
						margin: 0;
					}
				}
			}
		}

		.question {
			margin: 0rem 0.75rem 0 1.75rem;
			width: 100%;
			border-left: 1px solid $border-color;

			.title {
				@extend .button;
				padding-left: 1rem;
				color: $text-secondary;
				font-weight: 600;
				gap: 0;

				.subtitle {
					@extend .tabBarItems;
					color: $text-secondary;
				}
			}

			.questionContent {
				display: flex;
				align-items: center;
				padding-left: 0.25rem;
				margin: 0.25rem 0 0.25rem 0.5rem;
				border-radius: 0.5rem;

				&:hover {
					background: $state-hover;
				}

				.spacedFlex svg {
					margin-right: 0.75rem;

					&:hover {
						path {
							fill: $button-primary;
						}
					}
				}
			}
		}

		.selected {
			background: $state-hover;
			color: $button-primary;

			p {
				color: $button-primary;
			}
		}

		.question {
			.selected {
				background: $state-hover-selected;
				.title {
					color: $button-primary;
					padding-left: 0;
				}
			}
		}

		.avatarContainer {
			color: $text-secondary;
			font-size: 0.625rem;

			display: flex;
			align-items: center;
			gap: 0.1rem;
			margin-right: 0.5rem;
		}
	}

	.navigationFooter {
		@include padding(padding, 0.5rem 0.75rem);
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.5rem;

		.greyButton {
			color: $text-primary;
			margin: 0 0.75rem;
			width: calc(100% - 1.5rem);

			svg path {
				fill: $text-primary;
			}
		}

		.noBorderButton {
			width: 100%;
		}
	}
}

.deletePopup {
	position: absolute;
	display: flex;
	width: 17rem;
	height: 5.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border-radius: 12px;
	border: 1px solid var(--Border, #c1c7d3);
	background: #fff;
	box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
	overflow: hidden;

	.delete {
		@extend .button;
		@include padding(padding, 0.5rem 1rem);
		display: flex;
		height: 100%;
		width: 100%;
		background: none;
		border: none;
		color: $red;
		font-weight: 600;
		cursor: pointer;

		&:hover {
			background: $bg-grey;
		}
	}

	.duplicate {
		@extend .button;
		@include padding(padding, 0.5rem);
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		color: $text-secondary;
		background: none;
		border: none;
		font-weight: 600;
		cursor: pointer;

		&:hover {
			background: $bg-grey;
		}
	}
}
