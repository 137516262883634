@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.home {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.noBorderButton {
		@include padding(padding, 0.5rem 1rem);
		display: flex;
		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	.homeContent {
		@include padding(padding, 3.56rem 0);
		display: flex;
		flex-direction: column;
		gap: 1.25rem;
		height: calc(100% - 3.56rem);
		width: 45%;

		&.fixedHeight {
			padding: 3.56rem 0 0;
			box-sizing: content-box;
			height: 27.5rem; // 440px
			justify-content: space-between;
		}

		@media (max-width: $md) {
			@include padding(padding, 0);
			width: calc(100% - 2rem);

			&.fixedHeight {
				padding: 0;
				height: 100%;
				justify-content: flex-start;
			}
		}

		.flex {
			gap: 1rem;
			flex-wrap: wrap;

			.homeItemContainer {
				@include padding(padding, 1.25rem);
				border-radius: 0.5rem;
				background-color: $state-hover;
			}

			.homeItemText {
				@extend .tabBarItems;
				display: flex;
				flex-direction: column;
				flex: 1;
				gap: 0.5rem;

				color: $text-secondary;
				font-size: 0.875rem;
				font-weight: 600;

				h2 {
					color: $text-primary;
					font-size: 2rem;
					line-height: 2.375rem;
				}

				.h2Input {
					display: flex;
					align-items: center;
					gap: 1.25rem;
					border-radius: 0.5rem;
					cursor: pointer;
					width: fit-content;

					&:hover,
					&.active {
						background: $state-hover;
					}

					.iconContainer {
						@include padding(padding, 0.5rem 0.625rem);
						display: flex;
						align-items: center;
						justify-content: center;
						svg {
							height: 1.5rem;
							width: 1.5rem;
						}
					}
				}
			}

			.homeItemButtonContainer {
				display: flex;
				align-items: center;
				gap: 1rem;

				.greyButton {
					@include padding(padding, 0.625rem 1rem);

					&.padding {
						@include padding(padding, 0.5rem 0.625rem);
					}

					svg {
						width: 1.5rem;
						height: 1.5rem;
					}
				}
			}
		}

		.content {
			@include padding(padding, 2rem);
			display: flex;
			flex-direction: column;
			gap: 1.5rem;
			border-radius: 0.5rem;
			border: 1px solid $border-color;
			height: 100%;
			max-height: 41.25rem; // 660px
			width: 100%;
			overflow: auto;

			@media (max-width: $md) {
				max-height: calc(100% - 15rem);
			}

			&.heightAuto {
				height: auto;
			}

			.navigationContainer {
				@include padding(padding, 0.5rem 1.25rem);

				.navigation {
					@extend .button;
					color: $text-secondary;
					display: flex;
					gap: 0.25rem;
					border-bottom: 1px solid $border-color;

					.navigationItem {
						@include padding(padding, 0.625rem);
						cursor: pointer;

						&.active {
							color: $text-primary;
							border-bottom: 1px solid $text-primary;
						}
					}
				}
			}

			.checklistItemContainer {
				display: flex;
				flex-direction: column;
				gap: 1rem;

				.checklistItem {
					@include padding(padding, 1rem 1.25rem);
					display: flex;
					align-items: center;
					gap: 0.75rem;
					cursor: pointer;
					border-radius: 0.5rem;

					&:hover,
					&.active {
						background: $state-hover;

						.moreCLIcon {
							@include padding(padding, 0.5rem);
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 0.5rem;

							&:hover,
							&.activeIcon {
								background: $state-hover-grey;
							}

							svg {
								height: 1.25rem;
								width: 1.25rem;
							}
						}
					}

					.checklistInfo {
						display: flex;
						flex: 1;
						flex-direction: column;
						gap: 0.25rem;
					}

					.moreCLIcon {
						display: none;
					}
				}
			}

			.emptyData {
				display: flex;
				flex-direction: column;
				gap: 1.25rem;

				.emptyDataInfo {
					display: flex;
					flex-direction: column;
					gap: 0.5rem;

					.description {
						@extend .kicker;
						color: $text-secondary;
					}
				}
			}
		}
	}

	.iconButton {
		@extend .button;
		@include padding(padding, 0.5rem 1rem 0.5rem 0.625rem);
		font-weight: 600;
		white-space: nowrap;
		color: $bg-white;
		cursor: pointer;

		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		background: $button-primary;
		border-radius: 0.5rem;

		gap: 0.5rem;

		&.fitContentWidth {
			width: fit-content;
		}

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}

		&:hover {
			background: $button-hover;
		}
	}
}
