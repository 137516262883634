// Background
$bg-white: #ffffff;
$bg-grey: #f7f9fb;
$bg-ticket: #f3f6fc;

// Button
$button-primary: #4369ef;
$button-secondary: #49454f;
$button-hover: #5d80f9;

// Text
$text-primary: #1c1b1f;
$text-secondary: #49454f;
$text-placeholder: #777d90;

// Border
$border-color: #c1c7d3;
$selection-color: #b3cffc;

// State
$state-active: #ffffff;
$state-selected: #d4e3fd;
$state-hover: #f6f5f7;
$state-hover-selected: #eaf1fc;
$state-hover-grey: #d6d6d6;

// Feedback
$feedback-green: #1f844c;
$feedback-red: #c22e36;
$red: #e64632;
