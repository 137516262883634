@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.popupContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: rgba(0, 0, 0, 0.25);
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
}

.invitePopup {
	@include padding(padding, 1.5rem 1.75rem);
	width: 27rem;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 1rem;
	border-radius: 0.5rem;
	border: 1px solid $border-color;
	background: $bg-white;
	box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);

	h4 {
		font-weight: 600;
	}

	.subtitle {
		@extend .button;
		font-weight: 600;
		color: $text-primary;
	}

	p {
		margin: 0;
		span {
			font-weight: 600;
		}
	}

	.separator {
		width: 100%;
		height: 1px;
		background-color: $border-color;
	}

	.greyButton {
		color: $text-primary;
		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}

.linkContainer {
	display: flex;
	width: 100%;
	height: 5.25rem;
	align-items: center;
	justify-content: space-between;
	border: 1px solid $border-color;
	border-radius: 0.5rem;

	.primaryButton {
		position: absolute;
		right: 1.75rem;
		height: 3.25rem;
	}

	.linkInput {
		@include padding(padding, 0.75rem 0.5rem);
		@extend .button;
		width: calc(100% - 7rem);
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-self: stretch;
		border: none;
		background: none;
		resize: none;
	}

	.primaryButton {
		svg {
			width: 1rem;
			height: 1rem;
		}
	}
}
