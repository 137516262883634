@import 'src/colors';
@import 'src/typography';
@import 'src/mixins';

.foldersListPopup {
	position: absolute;
	z-index: 1000;

	display: flex;
	width: 19.38rem; // 310px
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	overflow: hidden;

	border-radius: 0.5rem;
	border: 1px solid $border-color;
	background: $bg-white;
	box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);

	.searchContainer {
		@include padding(padding, 0.75rem 1rem 0);
		width: 100%;

		.inputSearch {
			@include padding(padding, 0.88rem 1rem);
			outline: none;
			font-size: 1rem;

			display: flex;
			align-items: center;

			border-radius: 0.5rem;
			border: 0.0625rem solid $border-color;
			background: $bg-grey;
			width: 100%;

			&::placeholder {
				@extend .body;
				color: $text-placeholder;
			}

			&:focus {
				border: 0.0625rem solid $button-primary;
				outline: 0.0625rem solid $button-primary;
			}
		}
	}

	.subtitle {
		@extend .button;
		@include padding(padding, 0.5rem 1rem);
		color: $text-secondary;
		font-size: 0.75rem;
	}

	.content {
		display: flex;
		flex-direction: column;
		width: 100%;

		&.loadingHeight {
			height: 3rem;
		}

		.folderItem {
			@extend .body;
			@include padding(padding, 0.5rem 1rem);
			font-weight: 600;
			color: $text-secondary;
			display: flex;
			align-items: center;
			gap: 0.5rem;
			cursor: pointer;

			&:hover {
				background: $state-hover;
				color: $text-primary;

				svg path {
					fill: $text-primary;
				}
			}

			.folderIcon {
				display: flex;
				align-items: center;
				svg {
					width: 1.5rem;
					height: 1.5rem;
				}
			}
		}

		.fetchingSpinner {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			bottom: 1rem;
			width: 1.88rem;
			height: 1.88rem;
			left: 50%;
			transform: translateX(-50%);
			background: $bg-white;
			border-radius: 50%;
			box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.5);

			span {
				position: relative;
				width: 1rem;
				height: 1rem;
				border: 2px solid $button-primary;
				border-top: 0.12rem solid $bg-white;
				border-radius: 50%;
				animation: spin 1s linear infinite;
			}

			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}
	}
}
