@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.symbol {
	@extend .body;
	@include padding(padding, 0.5rem 0.75rem);
	display: flex;
	align-items: center;
	gap: 0.25rem;
	border: none;
	background: $bg-white;
	cursor: pointer;
	font-weight: 600;

	border-radius: 2rem;

	svg {
		width: 1rem;
		height: 1rem;
	}
}

.selectedSymbol {
	background: $state-hover;
}

.lateralPannelSection {
	@include padding(padding, 1rem 1.25rem 1rem 1.25rem);
	border-top: 1px solid $border-color;
}

.button {
	font-weight: 600;
}

.textSettings {
	@extend .settings;

	.criticalContainer {
		@extend .lateralPannelSection;
		display: flex;
		flex-direction: column;
		gap: 1.25rem;
	}
}

.dateSettings {
	margin-top: 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 0.75rem;

	.flex {
		gap: 1rem;
	}

	.column {
		@include padding(padding, 1rem 1.25rem 1rem 1.25rem);
		border-top: 1px solid $border-color;
	}
}

.numberSettings {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;

	.flex {
		gap: 1rem;
		flex-wrap: wrap;
	}

	.input {
		@include padding(padding, 0 1rem 0.15rem 1rem);
		margin-top: 0.5rem;
		background: $bg-white;
	}
}

.mediaSettings {
	@extend .settings;
	padding: 0;

	.row {
		margin-top: 0.5rem;
		transform: translateY(0.25rem);
		flex-wrap: wrap;
		gap: 0rem;

		.symbol {
			font-weight: 500;
			padding: 0.5rem 0.75rem;
		}
	}

	.column {
		@include padding(padding, 1rem 1.25rem 0.5rem 1.25rem);
		border-top: 1px solid $border-color;
		gap: 0rem;
	}

	.hashing {
		@include padding(padding, 0.5rem 0 0 0);
	}
}

.tagsContainer {
	@extend .button;
	@include padding(padding, 0.38rem 0.5rem);
	color: $text-secondary;
	text-align: center;
	font-weight: 600;

	display: flex;
	// width: 25rem;
	width: 100%;
	align-items: center;
	align-content: center;
	gap: 0.5rem;
	flex-wrap: wrap;
	border-radius: 0.5rem;
	border: 1px solid $border-color;
	background: $bg-white;

	.tag {
		@include padding(padding, 0.5rem 0.5rem 0.5rem 0.62rem);
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;

		cursor: pointer;

		border-radius: 0.5rem;
		border: 1px solid $border-color;
		background: $state-active;

		&:hover {
			background: $state-hover;
		}
	}

	svg {
		@include padding(padding, 0 0.25rem);
		width: 2rem;
		height: 2rem;

		path {
			fill: $text-secondary;
		}
	}
}

.tagsInput {
	@extend .button;
	height: 2.25rem;
	background: none;
	color: $text-secondary;
	text-align: center;
	outline: none;
	border: none;
	font-weight: 600;
}

.hiddenInput {
	visibility: hidden;
	position: absolute;
	white-space: pre;
}

.inputContainer {
	@include padding(padding, 0 1.25rem 1rem 1.25rem);
}
