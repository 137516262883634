@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.typeSelectionContainer {
	li {
		@extend .button;
		@include padding(padding, 0.5rem 1rem);
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 0.5rem;
		margin: 0;
		line-height: 2rem;
		cursor: pointer;

		svg {
			width: 1rem;
			height: 1rem;
			path {
				fill: $text-primary;
			}
		}

		.flex {
			svg {
				width: 1.5rem;
				height: 1.5rem;
			}
		}

		&:hover {
			background: $state-hover;
		}
	}
}

.typeSelectionButton {
	@include padding(padding, 0.5rem 1rem);
	@extend .button;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	width: 18.75rem;
	border: 1px solid $border-color;
	border-radius: 0.5rem;
	background: $button-primary;
	color: white;
	cursor: pointer;

	svg {
		width: 1.5rem;
		height: 1.5rem;
		path {
			fill: $bg-white;
		}
	}
}

.typeSelectionPopup {
	position: absolute;
	z-index: 1000;
	transform: translateY(-1rem);
	background: $bg-white;
	border-radius: 0.5rem;
	border: 1px solid $border-color;
	width: 20rem;
	overflow: hidden;
	box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);

	ul {
		width: 100%;
		margin: 0;
		padding: 0;
	}
}

.header {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	h5 {
		@include padding(padding, 1rem 1rem 0.25rem 1rem);
		color: $text-primary;
	}

	.searchInput {
		@extend .body;
		@include padding(padding, 0.75rem);
		margin: 0 0.5rem;
		border: 1px solid $border-color;
		border-radius: 0.5rem;
		background: $bg-grey;
	}

	.navigation {
		display: flex;
		flex-direction: column;
		width: 100%;
		border-bottom: 1px solid $border-color;

		.navButton {
			@include padding(padding, 0.5rem 1rem);
			@extend .button;
			color: $text-secondary;
			background: $bg-white;
			border: none;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 600;
			width: 100%;

			svg {
				width: 0.75rem;
				height: 0.75rem;
			}

			&:hover {
				background: $state-hover;
				color: $text-primary;
			}
		}

		.selectedNavButton {
			background: $state-hover-selected;
		}
	}
}

.finalPopup {
	position: absolute;
	background-color: red;
	z-index: 1000;
	width: 20rem;
	transform: translate(21rem, -1rem);
	overflow: hidden;

	border-radius: 0.5rem;
	border: 1px solid $border-color;
	background: $bg-white;
	box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);

	ul {
		list-style-type: none;
		padding-left: 0;
		margin: 0;
	}

	.title {
		@include padding(padding, 0.5rem 1.25rem 0 1.25rem);
		color: $text-secondary;
		font-size: 0.75rem;
		font-weight: 500;
		line-height: 1.5rem;
	}
}

.selected {
	background: $state-hover-selected;
	color: $button-primary;

	svg path {
		fill: $button-primary !important;
	}
}
