@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.navbar {
	@include padding(padding, 1.5rem);
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 4.5rem;
	width: 100%;
}

.border {
	border-bottom: 1px solid $border-color;
}

.flex {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2rem;
}

.logo {
	svg {
		width: 8rem;
		height: 8rem;
	}
}

.logoutButton {
	@extend .noBorderButton;
	color: $red;

	&:hover {
		color: $red;
	}
}
