@import 'src/colors';
@import 'src/typography';

.calendar {
	position: absolute;
	z-index: 1000;

	display: flex;
	width: 20rem;
	height: 31rem;
	flex-direction: column;
	align-items: center;
	gap: 0.25rem;

	border-radius: 0.5rem;
	border: 1px solid $border-color;
	background: $bg-white;

	box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);

	user-select: none;

	&.newDimensions {
		width: 19.87rem;
		height: 30.87rem;
	}
}

// --- MODES --- //

// RESPONSIVE
.container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}
.responsive {
	position: absolute;
	padding-top: 0.75rem;
	height: 27.5rem;
}
@media (min-width: 398px) {
	.responsive {
		position: relative;
		top: 0;
		left: 0;
	}
}

//SIMPLE
.simple {
	height: 30.5rem;
	position: fixed;
	top: calc(50% - 10.625rem);
	left: calc(50% + 20.75rem);

	@media screen and (max-width: $md) {
		position: relative;
	}

	.periodSelector {
		transform: translateY(-0.6rem);
	}
}

.simpleHeader {
	@include padding(padding, 1rem 0 0 1rem);
	// @include padding(padding, 1rem);
	@extend .button;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	width: 100%;
	// border-bottom: 0.06rem solid $border-color;
}

// INTEGRATED
.integrated {
	position: relative;
	border: none;
	box-shadow: none;
	width: 100%;
	background: none;
	z-index: 1;

	.section {
		height: fit-content;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.integratedWeek {
	@extend .integrated;
}

.nav {
	@include padding(padding, 0.5rem);
	width: 100%;
	display: flex;
	align-items: space-between;
	justify-content: space-evenly;

	border-bottom: 0.06rem solid $border-color;

	.navItem {
		@extend .button;
		font-weight: 600;
		letter-spacing: 0.1px;
		background: $bg-white;
		border: none;
		padding: 0.5rem 1rem;
		border-radius: 0.5rem;
		color: $text-secondary;
		cursor: pointer;

		&:hover {
			background: $state-hover;
			color: $text-primary;
		}
	}

	.selected {
		background: $state-hover;
		color: $text-primary;
	}
}

.periodSelector {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	width: 100%;

	.periodSelectors {
		width: calc(100% - 1rem);
		display: flex;
		align-items: space-evenly;
		justify-content: space-evenly;
	}

	.selectedPeriod {
		@extend .body;
		height: 1.25rem;
		font-weight: 500;
	}
}

.footer {
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	border-top: 0.06rem solid $border-color;

	button {
		@extend .button;
		font-weight: 600;
		padding: 0.62rem 1rem;
		margin: 0.5rem 0.25rem;
		border-radius: 0.5rem;
		border: none;
		background: $bg-white;
		color: $text-secondary;
		cursor: pointer;

		&:hover {
			background: $state-hover;
			color: $text-primary;
		}
	}

	.validate {
		@extend .button;
		color: $bg-white;
		background: $button-primary;
		width: 100%;
		margin: 0.5rem 0.75rem;
		font-weight: 600;

		&:hover {
			background: $button-hover;
			color: $bg-white;
		}

		span {
			font-weight: 500;
		}
	}

	.disabled {
		background: $bg-white;
		color: $text-placeholder;
		cursor: auto;

		&:hover {
			background: $bg-white;
			color: $text-placeholder;
		}
	}
}

.isTransformed {
	transform: translateY(-2.9rem);
}

.header {
	@extend .title-xs;
	width: calc(100% - 2rem);
	padding: 0 1rem 0.38rem 1rem;
	border-bottom: 0.06rem solid $border-color;
}

.yearSelector {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.yearTitle {
		color: $text-primary;
		font-size: 1rem;
		font-weight: 600;
		line-height: 1.38rem;
		padding-left: 1.25rem;
	}

	.orderButtons {
		padding-right: 0.62rem;

		gap: 0.5rem;
		display: flex;
		button {
			border: none;
			background: none;
			padding: 0.62rem;

			display: flex;
			align-items: center;
			justify-content: center;

			width: 2.12rem;
			border-radius: 0.5rem;

			&:hover {
				cursor: pointer;
				background: $state-hover;

				svg path {
					fill: $text-primary;
				}
			}
		}
		svg path {
			width: 1.5rem;
			height: 1.5rem;
			fill: $text-secondary;
		}
	}
}

.elements {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	max-height: 19.75rem;
	overflow-y: scroll;
}

.element {
	@extend .button;
	display: flex;
	align-items: center;
	width: calc(100% - 1.5rem);

	padding: 0.5rem 0.5rem 0.5rem 1rem;
	gap: 0.75rem;

	.iconTitle {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.75rem;
	}

	&:hover {
		background: $state-hover;
		color: $text-primary;
	}
}

.switch {
	justify-content: space-between;
}

.toComeButtons {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.toComeButton {
	@extend .button;
	background: $bg-white;
	border: none;
	border-radius: 0.5rem;
	padding: 0.62rem;
	width: 8.25rem;
	outline: 1px solid $border-color;
	margin: 0.62rem 0;

	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
	gap: 0.62rem;

	.ellipse {
		width: 12.4.69rem;
		height: 12.4.69rem;
		border-radius: 50%;
		border: 2px solid $border-color;
	}
}

.section {
	width: 100%;
	height: 26.56rem;
}

.selectedFilter {
	outline: 3px solid $button-primary;
	.ellipse {
		width: 0.5rem;
		height: 0.5rem;
		border: 4px solid $button-primary;
	}
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 1.12rem;
		height: 1.12rem;
	}
}

.selectedElement {
	justify-content: space-between;
	svg path {
		fill: $text-primary;
	}

	&:hover {
		background: $bg-ticket;
		color: $button-primary;

		svg path {
			fill: $button-primary;
		}
	}
}
