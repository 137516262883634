@import 'src/colors.scss';
@import 'src/typography.scss';

.flex {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
}

.greyButton {
	@extend .button;
	@include padding(padding, 0.5rem 1rem);
	font-weight: 600;
	position: relative;
	width: fit-content;
	color: $bg-white;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.5rem;
	gap: 0.5rem;
	background: $state-hover;
	border: none;
	color: $text-secondary;

	svg {
		width: 1.25rem;
		height: 1.25rem;
	}

	svg path {
		fill: $text-secondary;
	}

	&:hover {
		color: $text-primary;
		background: $state-hover-grey;
		svg path {
			fill: $text-primary;
		}
	}
}

.darkBackground {
	padding: 20px 0;
	z-index: 2000;
	position: fixed;
	width: 100%;
	height: calc(100% - 40px);
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	overflow: auto;
}

.pdfContainer {
	width: 595px; // A4
	height: auto;

	font-size: 12px !important;

	@media (max-width: 595px) {
		transform: scale(calc(100vw / 595));
	}

	@media (min-width: 595px) and (max-height: 842px) {
		transform: scale(calc(100vh / 842));
	}

	@media (min-width: 595px) {
		transform: scale(1);
	}
}

.pageSpacing {
	.pdfPage {
		margin: 30px auto;
	}
}

.pdfReport {
	all: unset;

	.pdfPage {
		width: 595px; // A4
		height: 842px; // A4
		page-break-after: always;
		background: white;
		line-height: normal;
	}
}

.page {
	height: 100%;
	padding: 40px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	.checklistColorImage {
		background: $button-primary;
		min-width: 40px;
		min-height: 40px;
		border-radius: 8px;
		margin-right: 12px;
	}

	.greyText {
		color: $text-secondary;
		font-size: 11px;
		font-weight: 400;
		line-height: 12px;

		span {
			font-weight: 500;
		}
	}

	.textColumn {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	.simpleColumn {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 20px;
	}

	.header {
		color: $text-secondary;
		font-size: 11px;
		font-weight: 500;

		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 20px;
		border-bottom: 2px solid $border-color;

		.column {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			width: 100%;
		}

		.spacedFlex {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: $text-primary;
			font-size: 12px;
			font-weight: 600;
		}

		img {
			width: 40px;
			height: 40px;
			object-fit: cover;
			border-radius: 8px;
			margin-right: 10px;
		}
	}

	.content {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 24px;

		.blueContainer {
			padding: 28px 20px;
			display: flex;
			flex-direction: column;
			gap: 10px;

			border-radius: 6px;
			background: #f7f9fb;
		}

		.title {
			color: $text-primary;
			font-size: 24px;
			font-weight: 600;
			line-height: normal;
		}

		.answer {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 10px;

			margin: 16px 0;
			color: $text-primary;
			font-size: 24px;
			font-weight: 600;
			line-height: normal;

			svg {
				min-width: 20px;
				max-width: 20px;
				min-height: 20px;
				max-height: 20px;
			}
		}

		.noAnswer {
			@extend .button;
			display: flex;
			align-items: center;
			gap: 6px;

			svg {
				width: 16px;
				height: 16px;
			}
		}

		img {
			width: 480px;
			max-height: 320px;
			border-radius: 6px;
			object-fit: cover;
		}

		.contextMedia {
			margin-top: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			gap: 8px;
		}

		.contextImg {
			width: 240px;
			aspect-ratio: 1;
		}
	}

	.footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		transform: translate(12px, 20px);

		color: $text-primary;
		font-size: 9px;
		font-weight: 400;
		line-height: 20px;
	}
}

.summary {
	.summaryFlex {
		margin: 10px 0 0 54px;
		width: calc(100% - 54px);
		transform: translateX(-26px);
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.summaryTitle {
		font-family: 'Inter', sans-serif;
		width: 100%;
		color: $text-primary;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.user {
		color: $text-secondary;
		font-size: 10px;
		font-weight: 500;
	}

	.validated {
		color: $text-secondary;
		font-family: Inter;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		border: 1px solid $border-color;
		padding: 4px 8px;
		border-radius: 4px;
	}

	.finishedFlex {
		margin: 16px 0 0 54px;
		display: flex;
		align-items: center;
		width: 100%;
		gap: 10px;
		color: $text-primary;
		font-family: Inter;
		font-size: 11px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
	}

	.checklistCoverColorImage {
		width: calc(100% - 50px);
		min-height: 190px;
		max-height: 190px;
		margin: 20px 0 0 20px;
		border-radius: 8px;
		transform: translateX(-8px);

		object-fit: cover;
	}

	.chapterColumn {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.chapterFlex {
		min-width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.summaryNumber {
		width: calc(100% - 54px);
		padding: 20px 0;
		// color: $text-primary;
		font-size: 12px;
		font-weight: 600;
		line-height: normal;
	}

	.chapters {
		height: 100%;
		display: flex;
		flex-direction: column;

		.chapterFlex {
			padding: 5px 20px;
			width: 420px;
			transform: translateX(-20px);
			overflow: hidden;
			border-radius: 8px;
			&:hover {
				background: $state-hover;
			}
		}

		.chapterColumn {
			display: flex;
			flex-direction: column;
			gap: 4px;
		}

		.chapterNumber {
			color: $text-secondary;
			font-size: 10px;
			font-weight: 400;
			line-height: normal;
		}

		.chapterTitle {
			color: $text-primary;
			font-size: 11px;
			font-weight: 600;
			line-height: normal;
		}

		.chapterContext {
			color: $text-primary;
			font-size: 10px;
			font-weight: 400;
			line-height: normal;
		}

		.chapterPage {
			color: $text-secondary;
			font-size: 9px;
			font-weight: 400;
			line-height: normal;
		}
	}
}

.buttonsFlex {
	.primaryButton {
		@include padding(padding, 0.5rem 1rem 0.5rem 0.75rem);
		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}
