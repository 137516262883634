@import 'src/typography';
@import 'src/colors';

.calendar {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: calc(100% - 2rem);

	padding-left: 1rem;
}

.header {
	width: calc(100% - 0.75rem);
	margin-top: 0.38rem;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.date {
		@extend .title-s;
		font-weight: 600;
	}

	.orderButtons {
		gap: 1rem;
		display: flex;
		button {
			border: none;
			background: none;
			padding: 0.62rem;

			display: flex;
			align-items: center;
			justify-content: center;

			width: 2.12rem;
			border-radius: 0.5rem;

			&:hover {
				cursor: pointer;
				background: $state-hover;

				svg path {
					fill: $text-primary;
				}
			}
		}
		svg path {
			width: 1.5rem;
			height: 1.5rem;
			fill: $text-secondary;
		}
	}
}

.days {
	@extend .button;
	display: flex;
	width: calc(100% - 0.97rem);
	justify-content: space-evenly;
	align-items: center;
	height: 2.5rem;

	border-bottom: 0.06rem solid $border-color;
	color: $text-placeholder;

	&.newWidth {
		width: calc(100% - 1.88rem);
	}

	.day {
		width: 3.12rem;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $text-secondary;
	}
}

.carousel {
	z-index: 0;
	// width: calc(21.00rem + 6 * 0.50rem);
	width: 21rem;
	overflow-x: scroll;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;
	transform: scale(0.8) translateY(-2.4375rem);

	display: flex;

	&.updateTransform {
		transform: scale(0.97);
	}

	&::-webkit-scrollbar {
		display: none;
	}

	.month {
		display: flex;
		flex-direction: column;
		scroll-snap-align: start;
		padding-top: 0.62rem;
	}

	.week {
		scroll-snap-align: start;
		display: flex;
		// gap: 0.50rem;
	}

	.day {
		@extend .button;
		font-weight: 600;

		border-radius: 50%;

		width: 3rem;
		height: 3rem;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			cursor: pointer;
			background: $state-hover;
		}
	}

	.container {
		position: relative;
	}

	.betweenDates {
		border-radius: 0px;
		background: $state-hover-selected;
	}

	.today {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10000;
		position: absolute;
		outline: 1px solid $border-color;
		width: 2.88rem;
		height: 2.88rem;
	}

	.empty {
		display: none;
	}

	.betweenDates.today {
		border-radius: 50%;
	}

	.gap {
		width: 3rem;
		height: 3rem;
		background: $state-hover-selected;
		position: absolute;
		z-index: -1;
		top: -0.25rem;
	}

	.left {
		left: -1.88rem;
	}

	.right {
		left: 1.88rem;
	}

	.disabled {
		display: none;
	}

	.selected {
		background: $state-hover-selected;
		color: $button-primary;
		border: 2px solid $button-primary;
		width: 2.81rem;
		height: 2.81rem;
	}

	.notDisplayed {
		.day {
			color: #9b9b9b;
		}
		.selected {
			background: #b3c2fa;
			color: $bg-white;
		}
	}
}

.integrated {
	padding: 0;

	.calendar {
		height: 3.12rem;
	}
	.header {
		width: calc(100% - 1.88rem);
	}
	.day {
		transform: translateY(-4px);
		outline: none;
	}
	.today {
		outline: 2px solid $border-color !important;
		width: 2.5rem;
		height: 2.5rem;
		background: $bg-ticket;
		border-radius: 50%;
	}
}

.week {
	@extend .integrated;

	.carousel {
		height: 3.75rem;
		transform: scale(0.8) translateY(-6px);
	}
}

.active {
	border-radius: 50%;
	color: $button-primary;
}

.badge {
	position: absolute;
	transform: translateY(13px);
	width: 0.38rem;
	aspect-ratio: 1;
	border-radius: 50%;
	background: $button-primary;
	z-index: 11000;
}
