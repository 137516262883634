@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.edit {
	@include padding(padding, 2rem 2rem 0 2rem);
	width: 100%;
	max-width: 50rem;
	background: $bg-white;

	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 0.5rem;
	}

	.context {
		display: flex;
		flex-direction: column;
		width: calc(100% + 2rem);
		transform: translateX(-1rem);

		.mediaSection {
			margin: 1rem 0;
		}
	}

	.historyButtons {
		@include padding(padding, 0.5rem 0 1.5rem 0);
		margin-top: 0.5rem;
		display: flex;
		gap: 0.5rem;
		width: 100%;
	}

	.space {
		@include padding(padding, 1.5rem);
		width: 100%;
	}

	.undoButton {
		display: flex;
		align-items: center;
		justify-content: center;
		background: none;
		border: none;
		aspect-ratio: 1;
		border-radius: 0.5rem;
		cursor: pointer;

		&:hover {
			background: $bg-grey;

			svg path {
				fill: $text-primary;
			}
		}
		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	.conditions {
		@include padding(padding, 1.5rem);
		margin-top: 1rem;
		border: 1px solid $border-color;
		border-radius: 0.5rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;

		.header {
			@extend .button;
			@include padding(padding, 1rem 0);
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid $border-color;

			.delete {
				@extend .button;
				border: none;
				background: none;
				cursor: pointer;
				color: #e64632;
			}
		}

		.content {
			@extend .button;
			@include padding(padding, 0 0 1.5rem 0);
			display: flex;
			flex-direction: column;
			gap: 1.5rem;
			font-weight: 600;
			display: flex;
			flex-direction: column;
			margin-top: 1.5rem;
			border-bottom: 1px solid $border-color;
		}

		.condition {
			@extend .button;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
		}

		.conditionButton {
			@extend .button;
			@include padding(padding, 0.25rem 1rem);
			background: $bg-grey;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 600;
			border: 1px solid $border-color;
			border-radius: 0.5rem;
			width: 25rem;
		}
	}

	.deletePopup {
		display: flex;
		width: 12rem;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		border-radius: 12px;
		border: 1px solid var(--Border, #c1c7d3);
		background: #fff;
		box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
		overflow: hidden;

		.delete {
			@extend .button;
			height: 100%;
			background: none;
			border: none;
			color: var(--Red, #e64632);
			font-weight: 600;
			cursor: pointer;

			&:hover {
				background: $bg-grey;
			}
		}
	}

	.subtitle {
		margin-left: 1rem;
	}

	.mediaSection {
		position: relative;
	}

	.coverImageButton {
		position: absolute;
		bottom: 1.5rem;
		right: 1.5rem;
	}

	.coverImage {
		width: 100%;
		height: 17rem;

		img {
			min-width: 100%;
			object-fit: cover;
		}
	}
}

.response {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.responseContainer {
		height: calc(100% - 3.125rem);
		background: $bg-white;
	}

	.content {
		@extend .hideScrollbar;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: auto;

		.headerNav {
			@include padding(padding, 1.25rem);
			display: flex;
			flex-direction: column;
			gap: 1.5rem;

			svg path {
				fill: $text-primary;
			}

			.greyButton {
				white-space: nowrap;
				margin: 0;
			}
		}

		.title {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			h4 {
				font-weight: 600;
			}
		}

		.context {
			@include padding(padding, 1.5rem);

			p {
				margin: 0.5rem 0;
			}

			.swipeableContainer {
				display: flex;
				overflow-x: scroll;
				scroll-snap-type: x mandatory;
				-webkit-overflow-scrolling: touch;
				scroll-behavior: smooth;
				scroll-snap-stop: always;
				width: 100%;

				&::-webkit-scrollbar {
					display: none;
				}

				.contextImageSection {
					scroll-snap-align: start;
					width: 100%;
					min-width: 100%;
					box-sizing: border-box;
					scroll-snap-stop: always;

					.contextImageTitle {
						@extend .button;
						color: $text-primary;
						font-weight: 600;
						margin-bottom: 0.5rem;
					}

					.contextImageContainer {
						position: relative;
						border-radius: 0.5rem;
						overflow: hidden;
						width: 100%;
						aspect-ratio: 1;

						display: flex;
						align-items: center;
						justify-content: center;
					}

					.contextImageIndex {
						@include padding(padding, 0.25rem 0.75rem);
						position: absolute;
						display: inline-flex;
						align-items: center;
						gap: 0.5rem;
						border-radius: 20px;
						background: rgba(73, 69, 79, 0.6);
						color: $bg-white;

						top: 0.75rem;
						right: 0.75rem;

						transform: scale(0.65);
					}

					.ellipsisContainer {
						@include padding(padding, 0.25rem 0.38rem);
						position: absolute;
						transform: scale(0.65);

						display: inline-flex;
						align-items: center;
						gap: 0.5rem;
						border-radius: 20px;
						background: rgba(73, 69, 79, 0.6);

						bottom: 0.25rem;
					}

					.ellipsis {
						width: 0.62rem;
						height: 0.62rem;
						background: $border-color;

						border-radius: 50%;
					}

					.selected {
						background: $bg-white;
					}

					.contextImage {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}

		.validate {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: flex-end;

			.primaryButton {
				margin: 0.75rem 1.25rem 1.25rem 1.25rem;
				width: calc(100% - 2.5rem);
				user-select: none;
			}

			.disabled {
				background: none;
				color: $text-placeholder;
			}
		}
	}

	.footerNav {
		@include padding(padding, 0 1.5rem);
		border-top: 1px solid $border-color;
		background: $bg-white;
		height: 3.125rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

@keyframes slideDownAnimation {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(120%);
	}
}

.slideDown {
	animation: slideDownAnimation 0.75s forwards;
}
