@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.lateralPannel {
	display: flex;
	flex-direction: column;
	border-left: 1px solid $border-color;
	height: 100%;
	min-width: 22.5rem;
	max-width: 22.5rem;
}

.lateralPannelSection {
	@include padding(padding, 1rem 1.25rem);
	cursor: pointer;
	.button {
		font-weight: 600;
	}
}
