@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.questionMenu {
	min-width: 22.5rem;
	max-width: 22.5rem;
	height: 100%;
	background: $bg-white;
	display: flex;
	flex-direction: column;

	.button {
		font-weight: 600;
	}

	.title {
		@include padding(padding, 1rem 1rem 1rem 1.65rem);
		font-weight: 600;
	}
}

.questions {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	overflow: auto;
}

.question {
	@include padding(padding, 1rem);
	margin: 0 0.5rem;
	display: flex;
	align-items: center;
	border-radius: 0.5rem;
	gap: 0.5rem;
	cursor: pointer;

	&:hover {
		background: $state-hover;
	}
}

.selected {
	background: $state-hover-selected;
	color: $button-primary;

	&:hover {
		background: $state-hover-selected;
	}
}

.questionActions {
	svg {
		width: 1.25rem;
		height: 1.25rem;

		path {
			fill: $button-primary;
		}
	}
}

.check {
	svg {
		padding: 0.125rem;
		width: 1rem;
		height: 1rem;

		path {
			fill: $text-secondary;
		}
	}
}

.chapterSelector {
	@include padding(padding, 1rem);
	display: flex;
	flex-direction: column;
	margin: 1rem;
	border: 1px solid $border-color;
	border-radius: 0.5rem;

	svg {
		width: 2rem;
		height: 2rem;
		path {
			fill: $text-secondary;
		}
	}

	.spacedFlex {
		@include padding(padding-right, 0.5rem);

		.navButtons {
			all: unset;
			cursor: pointer;
			border-radius: 0.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 3rem;
			height: 3rem;

			&:hover {
				background: $state-hover;
			}
		}
	}

	.title {
		@extend .button;
		@include padding(padding, 0.5rem);
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
	}

	.content {
		@extend .button;
		font-weight: 600;
		display: flex;
		flex-direction: column;
		gap: 0.25rem;

		span {
			color: $text-placeholder;
			font-weight: 400;
		}
	}
}
