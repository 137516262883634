@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.responsePage {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;
	background: var(
		--Bg-Gradient,
		linear-gradient(118deg, #f6d5f7 15.99%, #fbe9d7 70.58%)
	);
}

.navButtons {
	width: 100%;
	display: flex;
	flex-direction: row-reverse;

	.navButton {
		all: unset;
	}
}

.content {
	@include padding(padding, 2rem 1.5rem);
	@extend .hideScrollbar;
	width: calc(100% - 20rem);
	max-width: 60rem;
	display: flex;
	flex-direction: column;
	gap: 3rem;
	max-height: calc(100dvh - 10rem);
	background: white;
	border-radius: 0.5rem;
	overflow: auto;
}

.coverButtons {
	@include padding(padding, 0 1rem);
	display: flex;
	height: 0px;
	gap: 0.5rem;
	transform: translateY(-2.5rem);

	.noBorderButton {
		@include padding(padding, 0.5rem);
	}
}

.cover {
	width: 100%;
	img {
		border-radius: 0.5rem;
		width: 100%;
		height: 12.5rem;
		object-fit: cover;
	}
}

.header {
	@include padding(padding, 0 1.75rem);
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.title {
		color: $text-primary;
		font-size: 2rem;
		font-weight: 600;
		line-height: 2.25rem;
	}

	.modifiedBy {
		@extend .button;
		font-weight: 500;
		display: flex;
		align-items: center;
	}
}

.chapters {
	height: 100%;
	display: flex;
	flex-direction: column;

	.chaptersTitle {
		@include padding(padding, 0 0 1.5rem 1.75rem);
		color: $text-primary;
		font-size: 20px;
		font-weight: 600;
	}

	.chapterFlex {
		@include padding(padding, 1.5rem);
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 8px;
		cursor: pointer;
		&:hover {
			background: $state-hover;
		}
	}

	.chapterColumn {
		display: flex;
		flex-direction: column;
		gap: 0.25rem;
	}

	.chapterNumber {
		color: $text-secondary;
		font-size: 0.875rem;
		font-weight: 400;
		line-height: normal;
	}

	.chapterTitle {
		color: $text-primary;
		font-size: 1rem;
		font-weight: 600;
		line-height: normal;
	}

	.chapterContext {
		color: $text-primary;
		font-size: 0.875rem;
		font-weight: 400;
		line-height: normal;
	}

	svg {
		width: 1.5rem;
		height: 1.5rem;
		min-width: 1.5rem;
		min-height: 1.5rem;
	}

	.space {
		min-height: 1.5rem;
		min-width: 100%;
	}
}

.noResponsePage {
	@extend .responsePage;
	@include padding(padding, 2rem 1.5rem);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.content {
		width: 100%;
		gap: 1rem;
	}

	.primaryButton {
		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}

.noResponseContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.noResponseNav {
	width: 100%;
	max-width: 36rem;
	margin-bottom: 1rem;
	display: flex;
	flex-direction: row-reverse;
	gap: 1rem;
}
