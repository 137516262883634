@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.comments {
	display: flex;
	flex-direction: column;
	border-left: 1px solid $border-color;
	height: 100%;
	min-width: 22.5rem;
	max-width: 22.5rem;

	.header {
		@include padding(padding, 2rem 1.25rem);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.closeIcon {
		cursor: pointer;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		overflow: auto;

		li:last-child {
			border-bottom: none;
		}
	}

	.footer {
		@include padding(padding, 0.5rem 1.25rem);
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		.flex {
			width: 100%;
			margin-bottom: 0.75rem;
		}
	}

	h4 {
		font-weight: 600;
	}

	.spacedFlex {
		margin-bottom: 1rem;

		.column {
			gap: 0.5rem;
		}
	}

	li {
		@include padding(padding, 0.5rem 1.25rem);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 0.5rem;
		border-bottom: 1px solid $border-color;
	}

	.replyButton {
		padding: 0;
		background: none;
		border: none;
		color: $text-secondary;
		cursor: pointer;
	}

	.reply {
		border-left: 1px solid $border-color;
		border-bottom: none;
	}

	svg {
		min-width: 1.5rem;
		min-height: 1.5rem;
		path {
			fill: $text-primary;
		}
	}

	.primaryButton {
		@include padding(padding, 0.25rem 1rem);
		svg path {
			fill: $bg-white;
		}
	}
}

.noBorder {
	border-left: none;
}
