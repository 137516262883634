@import 'src/colors';
@import 'src/typography';

.loadingFullScreen {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	width: 100%;
	height: 100%;
	background: $bg-white;
}
