@import 'src/colors';
@import 'src/typography';

.coverImage {
	position: relative;
	overflow: hidden;
	width: 2.5rem;
	min-width: 2.5rem;
	height: 2.5rem;
	border-radius: 0.5rem;
	color: $bg-white;
	text-transform: uppercase;
	background: $red;
	display: flex;
	align-items: center;
	justify-content: center;

	&.small {
		width: 1.12rem;
		min-width: 1.12rem;
		height: 1.12rem;
	}

	&.big {
		width: 3.75rem;
		min-width: 3.75rem;
		height: 3.75rem;
	}

	&.noBg {
		background: none;
	}

	.fitImage {
		border-radius: 0.375rem;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}
