@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.rules {
	@extend .hideScrollbar;
	@include padding(padding, 0.5rem 1.5rem);
	position: relative;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	overflow: auto;

	.noRules {
		@include padding(padding, 1rem);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 6px;
		border-radius: 8px;
		background: #fdf4e5;

		.noBorderButton {
			width: 100%;
		}
	}

	.header {
		@extend .button;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.delete {
			@extend .greyButton;
			width: 2.75rem;

			svg {
				width: 1.5rem;
				height: 1.5rem;
				min-width: 1.5rem;
				min-height: 1.5rem;
				max-width: 1.5rem;
				max-height: 1.5rem;
			}
		}
	}

	.content {
		@extend .button;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		font-weight: 600;
		display: flex;
		flex-direction: column;
		margin-top: 1rem;
	}

	.condition {
		@extend .button;
		font-weight: 600;
		display: flex;
		flex-direction: column;
		gap: 0.25rem;
	}

	.conditionButton {
		@extend .button;
		@include padding(padding, 0.75rem 1rem);
		margin-top: 0.5rem;
		gap: 0rem;
		background: $state-hover;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: 600;
		border: none;
		border-radius: 0.5rem;
		cursor: pointer;
		color: $text-secondary;

		span {
			color: $text-secondary;
			font-weight: 400;
		}

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}

.rule {
	@include padding(padding, 01rem);
	border: 1px solid $border-color;
	border-radius: 0.5rem;
}

.rulesPopup {
	position: fixed;
	top: 10rem;
	right: 23.5rem;
	background: $bg-white;
	display: flex;
	width: 22rem;
	max-height: 36rem;
	z-index: 10000;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border-radius: 0.5rem;
	border: 1px solid $border-color;
	box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
	overflow: hidden;

	.inputContainer {
		@include padding(padding, 0.5rem 1rem);
		width: 100%;
	}

	.header {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 0.5rem;

		h5,
		h6 {
			@include padding(padding, 0 1rem);
		}

		.spacedFlex {
			@include padding(padding, 1rem 0.67rem 0 0.67rem);
			border-top: 1px solid $border-color;
		}

		.secondaryButton {
			width: 100%;
		}
	}

	ul {
		@extend .hideScrollbar;
		margin: 0;
		padding: 0;
		overflow: auto;
		width: 100%;
	}

	li {
		@extend .button;
		@include padding(padding, 0.75rem 1rem);
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		background: $bg-white;
		cursor: pointer;

		&:hover {
			background: $bg-grey;
		}
	}

	.border {
		border-bottom: 1px solid $border-color;
	}

	span {
		font-weight: 500;
	}

	.column {
		display: flex;
		flex-direction: column;
	}

	.chapter {
		border-top: 1px solid $border-color;
	}

	.noBorder {
		border-top: none;
	}
}

.conditions {
	@extend .button;
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;

	svg {
		transform: translateY(-0.25rem);
		min-width: 1.5rem;
		min-height: 1.5rem;
		path {
			fill: $text-primary;
		}
	}
}
