@import 'src/colors';
@import 'src/typography';

.counter {
	margin-top: 0.5rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;

	button {
		cursor: pointer;
		border: 1px solid $border-color;
		width: 2rem;
		height: 2rem;
		background: $bg-white;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background: $bg-grey;
		}
	}
}

.count {
	@extend .title-s;
	color: $text-primary;
	font-weight: 600;
}
