@import 'src/colors';

.switch {
	min-width: 38px;
	height: 20px;

	position: relative;

	background-color: #bfbfbf;
	border-radius: 16px;
	cursor: pointer;

	.toggle {
		width: 18px;
		height: 18px;

		position: absolute;

		left: 1px;
		top: 1px;
		border-radius: 50%;
		background-color: $bg-white;
		transition: transform 0.2s ease;
	}

	&.on {
		background-color: $button-primary;
		.toggle {
			transform: translateX(18px);
		}
	}
}
