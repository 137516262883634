@import 'src/colors';
@import 'src/typography';

.dateSelector {
	position: relative;
	z-index: 0;

	.title {
		@extend .title-xs;
		color: $text-placeholder;
	}

	input {
		@extend .title-xs;
		@include padding(padding, 0 0.75rem);
		font-size: 0.88rem;
		width: 8.25rem;
		height: 2.5rem;
		outline: 1px solid $border-color;
		border: none;
		border-radius: 0.5rem;
		color: $text-primary;
		background: none;
	}

	input:focus {
		outline: 2px solid $button-primary;
	}

	input::selection {
		background: $selection-color;
		color: $text-primary;
	}

	&::placeholder {
		color: $text-placeholder;
	}

	.fakePlaceholder {
		@extend .title-xs;
		font-size: 0.88rem;
		color: $text-placeholder;
		position: absolute;
		top: 2.02rem;
		left: 0.75rem;
		pointer-events: none;
		z-index: -1;
	}
}

.simple {
	width: calc(100% - 1.5rem);
	input {
		width: 100%;
	}
}
