@import 'colors';
@import 'typography';
@import 'variables';
@import 'common';

@mixin responsiveFont($baseSize, $breakpoints) {
	font-size: $baseSize;

	@each $breakpoint in $breakpoints {
		@media screen and (min-width: nth($breakpoint, 1)) {
			font-size: nth($breakpoint, 2);
		}
	}
}

#root {
	height: 100%;
	overflow: hidden;
}

html,
body {
	top: 0;
	height: 100%;
	margin: 0;

	font-size: 16px; // 1rem = 16px

	@media (max-width: $xs) {
		font-size: 16px;
	}
	@media (min-width: $sm) and (max-width: $md) {
		font-size: 16px;
	}
	@media (min-width: $md) and (max-width: $lg) {
		font-size: 11.2px; // 16px * 0.7 = 11.2px
	}
	@media (min-width: $lg) and (max-width: $xl) {
		font-size: 12px; // 16px * 0.75 = 12px
	}
	@media (min-width: $xl) and (max-width: $hd) {
		font-size: 12.8px; // 16px * 0.8 = 12.8px
	}
	@media (min-width: $hd) and (max-width: $fhd) {
		font-size: 13.6px; // 16px * 0.85 = 13.6px
	}
	@media (min-width: $fhd) and (max-width: $uhd) {
		font-size: 14.4px; // 16px * 0.9 = 14.4px
	}
	@media (min-width: $uhd) and (max-width: $quhd) {
		font-size: 15.2px; // 16px * 0.95 = 15.2px
	}
	@media (min-width: $quhd) {
		font-size: 16px;
	}

	font-family: $font-family;
	font-weight: $font-weight-body;
	line-height: $line-height-body;
	letter-spacing: $letter-spacing-body;
	margin: 0;
	padding: 0;
	background-color: #fff;
}

// Remove mobile button highlight
* {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Safari */
	-webkit-focus-ring-color: rgba(0, 0, 0, 0); /* Chrome */
}

body {
	overflow: hidden;
}

*:focus-visible {
	outline: none;
}

.section {
	position: absolute;
	top: 4.5rem;
	width: 100%;
	height: calc(100% - 4.5rem);
	overflow: hidden;

	// @media screen and (max-width: $fhd) {
	// 	top: 54px;
	// 	height: calc(100% - 54px);
	// }
}

a,
a:visited {
	text-decoration: none !important;
}

.App {
	height: 100%;
	max-height: 100%;
	position: relative;
	top: 0;
	display: flex;
	flex-direction: column;
}

.temporary {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	// background: rgba(0, 0, 0, 0.5);
	// z-index: 1000;
	overflow: auto;
}

.mainContent {
	margin-top: 0;
	height: 100%;
	// height: -webkit-fill-available;
	min-height: -webkit-fill-available;
	overflow: hidden;
	// overflow-y: scroll;

	@media screen and (min-width: $xl) {
		margin-top: 4.5rem;
		height: calc(100% - 4.5rem);
	}

	@media screen and (max-width: $fhd) {
		margin-top: 4.5rem;
		height: calc(100% - 4.5rem);
	}
}

// SCROLLBAR //

* {
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background: $border-color;
		border-radius: 0.5rem;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #9ca1aa;
	}
}

// INPUT - Remove icons when type === number //
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
	appearance: textfield;
}

input[disabled] {
	color: $text-primary;
	opacity: 1;
}

// DATE PICKER //
.react-datepicker {
	transform: scale(1.5);
	left: 2.5rem;
	top: 1.875rem;
}

.react-datepicker__header {
	background-color: $bg-grey !important;
	border-bottom: 1px solid $border-color !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	color: $text-primary !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	font-family: Inter !important;
	font-weight: 500 !important;
}

.react-datepicker {
	font-family: Inter !important;
	font-weight: 500 !important;
	border: 1px solid $border-color !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: $text-primary !important;
}

.react-datepicker__day--selected {
	font-family: Inter !important;
	font-weight: 500 !important;
	background-color: $button-primary !important;
	color: white !important;
}

.react-datepicker__navigation-icon {
	transform: scale(0.75);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
	border-color: $text-secondary !important;
}

.react-datepicker__triangle {
	display: none !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	background-color: transparent !important;
	font-weight: 500 !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
	background-color: $state-selected !important;
	font-weight: 500 !important;
}

.react-datepicker__navigation-icon--next::before {
	border-radius: 2px !important;
}

.react-datepicker__navigation-icon--previous::before {
	border-radius: 2px !important;
}

.react-datepicker__day--outside-month {
	color: $text-placeholder !important;
}

// GOOGLE MAPS
.gm-control-active {
	display: none;
}
.gm-bundled-control {
	display: none;
}
.gm-style div {
	border: none !important;
}
.gm-style-mtc {
	display: none;
}
.googleMapsActivateButton {
	@extend .button;
	position: absolute;
	left: 50%;
	top: 50%;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	z-index: 1;
	background: $bg-white;
	border-radius: 0.5rem;
	border: none;
	height: 3.125rem;
	width: max-content;
	padding: 0px 1rem;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

// //

// // .App {
// //   text-align: center;
// // }

// .App-logo {
// 	height: 40vmin;
// 	pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
// 	.App-logo {
// 		animation: App-logo-spin infinite 20s linear;
// 	}
// }

// .App-header {
// 	background-color: #282c34;
// 	min-height: 100vh;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: center;
// 	font-size: calc(10px + 2vmin);
// 	color: white;
// }

// .App-link {
// 	color: #61dafb;
// }

// @keyframes App-logo-spin {
// 	from {
// 		transform: rotate(0deg);
// 	}
// 	to {
// 		transform: rotate(360deg);
// 	}
// }
