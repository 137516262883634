@import 'src/colors';
@import 'src/mixins';
@import 'src/typography';

.toast {
	@extend .button;
	@include padding(padding, 0.62rem);
	position: fixed;
	top: 5.12rem;
	left: 50%;
	transform: translateX(-50%);
	min-width: 13.75rem;
	z-index: 3000;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	text-align: center;

	border-radius: 0.5rem;
	border: 1px solid $border-color;
	background: $state-hover;

	/* Drop-Shadow */
	box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);

	color: $text-primary;
	line-height: 1rem;

	&.copyClipboard {
		background: #170037;
		color: $bg-white;
		border: 1px solid #170037;
	}

	svg {
		height: 1.25rem;
		width: 1.25rem;
	}

	.error {
		display: flex;
		svg path {
			fill: $red;
		}
	}
}
