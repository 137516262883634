@import 'src/colors';

.bg {
	pointer-events: none;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);
	z-index: 4000;
}

.sheet {
	position: fixed;
	height: calc(100% + 6.25rem);
	width: calc(100% - 0.12rem);
	left: 0;
	touch-action: none;

	border-radius: 28px 28px 0px 0px;
	background: #fff;
	border: 1px solid $border-color;
	background: $bg-white;

	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
		0px 4px 8px 3px rgba(0, 0, 0, 0.15);

	z-index: 5000;
}
