@import 'src/colors';
@import 'src/mixins';
@import 'src/typography';

.container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	user-select: none;

	.loginSection {
		display: flex;
		width: 100%;
		max-width: 37.5rem;
		height: 100%;
		justify-content: center;

		@media (max-width: $lg) {
			padding: 0 20px;
			box-sizing: border-box;
			align-items: flex-start;
		}

		.login {
			display: flex;
			flex-direction: column;
			gap: 7.6rem;
			max-width: 25rem;
			width: 100%;

			@media (max-width: $lg) {
				gap: 3.75rem;
			}

			.logo {
				@include padding(padding, 3.75rem 0 0);
			}

			.form {
				display: flex;
				flex-direction: column;
				gap: 1.25rem;

				.title {
					@extend .title-xl;
					color: $text-primary;
				}

				.formContainer {
					display: flex;
					flex-direction: column;
					gap: 1.25rem;

					.divider {
						@include padding(padding-bottom, 1.5rem);
						border-bottom: 0.0625rem solid $border-color;
					}

					.group {
						display: flex;
						flex-direction: column;
						gap: 0.5rem;

						.inputTitle {
							@extend .button;
							color: $text-primary;
						}

						.loginInput {
							@include padding(padding, 0 0.75rem);
							height: 2.5rem;
							max-width: 100%;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;

							gap: 1.62rem;
							border-radius: 0.5rem;
							border: none;
							outline: 1px solid $border-color;
							background: $bg-grey;

							&:focus-within {
								outline: 2px solid $button-primary;
							}
						}

						.pwdInputContainer {
							position: relative;

							.eyeIcon {
								position: absolute;
								top: 50%;
								right: 1rem;
								transform: translateY(-50%);
								width: 1.5rem;
								height: 1.5rem;
								cursor: pointer;

								path {
									fill: $text-secondary;
								}
							}
						}
					}
				}

				.continueButton {
					@extend .button;
					@include padding(padding, 0.62rem 1rem);
					font-weight: 600;
					border: none;

					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					gap: 0.5rem;
					border-radius: 0.5rem;
					background: $button-primary;
					color: $bg-white;
					text-align: center;
					cursor: pointer;

					&:hover {
						background: $button-hover;
					}

					@media (min-width: $md) {
						max-width: 400px;
					}
				}

				.forgotPassword {
					@extend .button;
					@include padding(padding-top, 0.25rem);
					color: $text-secondary;
					font-weight: 600;

					span {
						color: $text-primary;
						text-decoration: underline;
					}
				}
			}
		}
	}

	.desktopSection {
		position: relative;
		display: none;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;

		@media (min-width: $lg) {
			display: flex;
		}

		.imgCopyRight {
			position: absolute;
			bottom: 1.375rem;
			right: 2.06rem;
			color: $bg-white;
			cursor: pointer;

			span {
				text-decoration: underline;
			}
		}

		img {
			object-fit: cover;
			height: inherit;
			width: 100%;
		}
	}
}

.spinner {
	position: relative;
	width: 1rem;
	height: 1rem;
	border: 2px solid rgba(255, 255, 255, 0.3);
	border-top: 0.12rem solid $bg-white;
	border-radius: 50%;
	animation: spin 1s linear infinite;
	top: 0.62rem;
}

@keyframes spin {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
