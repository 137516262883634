@import 'src/colors';
@import 'src/typography';

.avatar {
	min-width: 1.88rem;
	min-height: 1.88rem;
	max-width: 1.88rem;
	max-height: 1.88rem;
	border-radius: 50%;
	border: 1px solid $bg-white;
	color: $bg-white;
	text-transform: uppercase;
	background: $red;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.88rem;
	font-weight: 600;
	position: relative;
	left: 0;
	z-index: 5;

	&.small {
		min-width: 1.12rem;
		min-height: 1.12rem;
		max-height: 1.12rem;
		max-width: 1.12rem;
		font-size: 0.5rem;
		font-weight: 300;
	}

	&.medium {
		width: 1.25rem;
		height: 1.25rem;
		min-width: 1.25rem;
		min-height: 1.25rem;
		max-height: 1.25rem;
		max-width: 1.25rem;
		font-size: 0.62rem;
	}

	&.big {
		width: 2.5rem;
		height: 2.5rem;
		min-width: 2.5rem;
		min-height: 2.5rem;
		max-height: 2.5rem;
		max-width: 2.5rem;
		font-size: 1.12rem;
	}

	&.noBg {
		background: none;
	}

	@for $i from 2 through 5 {
		&:nth-child(#{$i}) {
			left: calc(-0.5rem * #{$i - 1});
			z-index: #{5 + $i};
		}
	}
}

.column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transform: translateY(calc(25% * var(--avatar-count, 1)));

	@for $i from 2 through 5 {
		&:nth-child(#{$i}) {
			top: calc(-0.25rem * #{$i - 1});
			left: 0;
			z-index: #{5 + $i};
		}
	}
}
