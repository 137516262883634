@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.edit {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: $bg-white;

	.primaryButton {
		@include padding(padding, 0.5rem 1rem 0.5rem 0.75rem);

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 1rem;

		button {
			all: unset;
			display: flex;
			align-items: center;
			justify-content: center;
			aspect-ratio: 1;
			border-radius: 0.5rem;
			cursor: pointer;
			margin-bottom: 1rem;
			margin-right: 1rem;

			svg {
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}

	.backArrow {
		border: none;
		background: none;
		cursor: pointer;
		padding: 0.5rem;
		border-radius: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background: $state-hover;
		}

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	.verticalSeparator {
		width: 1px;
		height: 2.5rem;
		background: $border-color;
		margin-left: 1rem;
	}

	.content {
		all: unset;
		display: flex;
		height: calc(100% - 4.5rem);
	}

	.settings {
		@extend .hideScrollbar;
		all: unset;
		display: flex;
		justify-content: center;
		width: calc(100% - 17rem);
		overflow: hidden;
		overflow-y: scroll;
	}
}

.response {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;

	.navigation {
		@include padding(padding, 0 0 1.5rem 0);
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid $border-color;

		.header {
			@include padding(padding, 1rem);
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.spacedFlex {
			@include padding(padding, 0.5rem 2rem);

			svg {
				width: 1.75rem;
				height: 1.75rem;
			}
			img {
				width: 10rem;
				height: 10rem;
				overflow: hidden;
				object-fit: cover;
				border-radius: 0.5rem;
			}
		}

		.title {
			@include padding(padding, 1.25rem);
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			h4 {
				font-weight: 600;
			}
		}

		.navButton {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-evenly;

			.greyButton {
				color: $text-primary;
				svg path {
					fill: $text-primary;
				}
			}
		}
	}

	.content {
		@include padding(padding, 1rem 1.25rem);
		max-height: calc(100% - 27rem);
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		overflow: hidden;
		overflow-y: scroll;

		h5 {
			margin-bottom: 0.5rem;
		}
	}

	.footer {
		background: $bg-white;
		min-height: 3.5rem;
		display: flex;
		align-items: center;
		justify-content: center;

		.primaryButton {
			width: 85%;
		}
	}
}

.checklist {
	@include padding(padding, 0 10rem);
	width: calc(100% - 17rem);
	display: flex;
	flex-direction: column;
	display: flex;
	gap: 1rem;
	justify-content: center;
	overflow: auto;

	> * {
		flex-shrink: 0;
	}

	.imgContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 15rem; //240px
		width: 100%;

		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 0.5rem;
		}
	}
}

.chapter {
	@include padding(padding, 3rem 2rem 0 2rem);
	width: 100%;
	max-width: 50rem;
	background: $bg-white;
	display: flex;
	flex-direction: column;

	> * {
		flex-shrink: 0;
	}

	.flex {
		font-weight: 600;
		color: $text-secondary;
	}

	.contextSubtitle {
		@include padding(padding, 1rem);
	}

	.deleteButton {
		width: fit-content;
		display: flex;
		align-items: center;
		background: none;
		border: none;
		color: #ff0000;
		cursor: pointer;
	}

	.createButton {
		@include padding(padding, 0.5rem 1rem 0.5rem 0.75rem);
		background: $button-primary;
		border: none;
		color: $bg-white;
		border-radius: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
		height: 2.5rem;
		cursor: pointer;
		&:hover {
			background: $button-hover;
		}
	}

	.coverImageButton {
		position: absolute;
		bottom: 1.5rem;
		right: 1.5rem;
	}

	.coverImage {
		width: 100%;
		height: 17rem;

		img {
			min-width: 100%;
			object-fit: cover;
		}
	}

	.titles {
		display: flex;
		flex-direction: column;
		transform: translateX(-1rem);
		margin-bottom: 1rem;
	}

	.buttonsFlex {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		font-weight: 600;
		color: $text-secondary;
		padding-bottom: 2rem;
	}

	.noBorderButton {
		color: $red;
	}
}

.separator {
	width: 100%;
	border-bottom: 1px solid $border-color;
}

.emptyPlaceholder {
	width: 22.5rem;
	height: 45rem;
}

.responseContainer {
	position: relative;
	height: calc(100% - 0rem);
	overflow: hidden;

	.flex {
		height: 100%;
	}

	.primaryButton {
		@include padding(padding, 0.5rem 1rem 0.5rem 0.75rem);
		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}
