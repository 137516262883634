@import 'src/colors';
@import 'src/typography';
@import 'src/mixins';

.folderList {
	display: flex;
	flex-wrap: wrap;
	height: 100%;

	&.autoHeight {
		height: auto;
	}

	.folderItem {
		@include padding(padding, 1rem 1.25rem);
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
		cursor: pointer;
		border-radius: 0.5rem;
		width: calc(100% / 3);
		aspect-ratio: 1 / 1;

		&:hover {
			background: $state-hover;
		}

		.checklistImages {
			height: 100%;

			.imageColumn {
				display: flex;
				gap: 0.5rem;
				height: 100%;

				.imageLine {
					display: flex;
					flex-direction: column;
					gap: 0.5rem;
				}
			}
		}

		.noChecklists {
			display: flex;
			align-items: center;
			justify-content: center;
			background: $bg-ticket;
			border-radius: 0.375rem;
			height: 100%;
		}

		.folderInfo {
			display: flex;
			flex-direction: column;
			gap: 0.125rem;
			color: $text-primary;

			p {
				color: $text-secondary;
				margin: 0;
			}
		}
	}
}
