@import 'src/colors';
@import 'src/mixins';
@import 'src/typography';
@import 'src/common';

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
	gap: 1.5rem;

	.imagesContainer {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.deleteIcon {
		@include padding(padding, 0.25rem);
		position: absolute;
		right: 5%;
		top: 5%;
		z-index: 1000;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;

		svg {
			width: 1rem;
			height: 1rem;

			path {
				fill: white;
			}
		}
	}

	.noBorderButton {
		color: $text-placeholder;
		svg path {
			fill: $text-placeholder;
		}
	}

	.section {
		@extend .title-s;
		margin: 0.5rem;
		width: calc(50% - 1rem);
		aspect-ratio: 1;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;

		color: $text-primary;
		font-weight: 600;
		position: relative;
	}

	.imageContainer {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			border-radius: 0.5rem;
			width: 100%;
			height: 100%;
			max-width: 100% !important;
			max-height: 100% !important;
			object-fit: cover;
		}
	}

	.withTitle {
		width: 15rem;
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		max-width: 25rem;
		gap: 0.5rem;

		.nameInput {
			@extend .body;
			@include padding(padding, 0.75rem);
			width: 100%;
			display: flex;
			align-items: flex-start;

			gap: 0.62rem;
			border-radius: 0.5rem;
			border: none;
			outline: 1px solid $border-color;
			background: $bg-white;

			&:focus {
				outline: 2px solid $button-primary;
			}
		}
		.delete {
			color: $red;
			cursor: pointer;
		}
	}

	.iconButton {
		@extend .button;
		@include padding(padding, 0.5rem 1rem 0.5rem 0.62rem);
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
		border-radius: 0.5rem;
		background: $state-hover;

		color: $text-primary;
		text-align: center;
		font-weight: 600;

		cursor: pointer;

		svg path {
			fill: $text-primary;
		}
	}

	.disabled {
		opacity: 0.5;
	}
}

.big {
	width: 100% !important;
	height: 100% !important;
	overflow: hidden;
}

.context {
	width: 100%;

	.section {
		width: 100%;
		height: 15rem;
	}

	.imagesContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}
