@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.gradientContainer {
	width: 100%;
	height: 100%;
	background: linear-gradient(118deg, #f6d5f7 15.99%, #fbe9d7 70.58%);
	border-radius: 1rem;
}

.scrollContainer {
	@extend .hideScrollbar;
	position: relative;
	height: 100%;
	width: 100%;
	overflow-y: auto;
	scroll-snap-type: y mandatory;
	// padding: 50% 0;
	box-sizing: border-box;

	> div {
		scroll-snap-align: center;
		scroll-snap-stop: always;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;

	> div:first-child {
		margin-top: calc(50dvh - 22.5rem);
	}

	> div:last-child {
		margin-bottom: calc(50dvh - 22.5rem);
	}
}

.checklist {
	width: 22.5rem;
	height: 45rem;
	display: flex;
	justify-content: center;
}

.navButtons {
	@include padding(padding, 0 1rem);
	position: fixed;
	bottom: calc(50% - 25rem);
	left: calc(50% + 12rem);
	width: 10rem;
	height: 45rem;

	display: flex;
	flex-direction: column;
	gap: 12rem;

	pointer-events: none;

	button {
		all: unset;
		background: $bg-white;
		border-radius: 50%;
		aspect-ratio: 1;
		width: 2.5rem;
		height: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		svg {
			width: 1.5rem;
			height: 1.5rem;

			path {
				fill: $text-secondary;
			}
		}

		&:hover {
			background: $state-hover;

			svg {
				path {
					fill: $text-primary;
				}
			}
		}
	}

	.group {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		pointer-events: auto;
	}
}
