@import 'src/colors';
@import 'src/mixins';
@import 'src/typography';
@import 'src/variables';

.settings {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.row {
		@include padding(padding, 0 1px);
		display: flex;
		align-items: flex-end;
		gap: 1rem;
	}

	.column {
		display: flex;
		flex-direction: column;
		gap: 0.625rem;
	}

	.title {
		@extend .title-s;
		font-weight: 600;
		margin: 1rem 0 0 0;
	}

	.subtitle {
		@extend .body;
		color: $text-secondary;
	}

	.iconButton {
		@extend .button;
		@include padding(padding, 0.5rem 1rem 0.5rem 0.625rem);
		background: $state-hover;
		font-weight: 600;

		display: flex;
		justify-content: center;
		align-items: center;

		border: none;
		width: fit-content;
		gap: 0.5rem;
		border-radius: 0.5rem;

		cursor: pointer;

		svg {
			width: 0.75rem;
			height: 0.75rem;

			path {
				fill: $text-primary;
			}
		}

		&:hover {
			background: $state-hover-grey;
		}
	}

	.option {
		width: 25rem;

		gap: 0.5rem;
		border-radius: 0.5rem;
		border: 1px solid $border-color;
		background: $bg-white;

		display: flex;
		align-items: center;
		justify-content: flex-end;

		textarea {
			@include padding(padding, 0.75rem 0.75rem 0.75rem 2rem);
			display: flex;
			width: 20.8125rem;
			align-items: center;
			outline: none;
			border-radius: 0.5rem;

			&:hover {
				background: $state-hover;
			}
		}

		&:hover {
			background: $state-hover;

			textarea {
				background: $state-hover;
			}

			.deleteIcon {
				display: block;
			}
		}
	}

	.deleteIcon {
		display: none;
		position: absolute;
		margin-right: 0.625rem;
		width: 1.25rem;
		height: 1.25rem;
		cursor: pointer;
	}
}

.answer {
	@extend .button;
	@include padding(padding, 0.75rem 0.5rem);
	border: none;
	width: 100%;
	border-radius: 0.5rem;
	// outline: 1px solid $border-color;
	background: $bg-white;
	// word-break: break-all;
	word-break: break-word;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: space-between;

	&:hover {
		background: $state-hover;
	}

	.flex {
		display: flex;
		gap: 0.375rem;
	}

	svg {
		@include padding(padding, 0 0.25rem);
		width: 1.25rem;
		height: 1.25rem;
	}

	.bubble {
		min-width: 1rem;
		min-height: 1rem;
		border-radius: 50%;
		outline: 2px solid $border-color;
	}

	.checkbox {
		width: 1.1875rem;
		height: 1.125rem;
		border-radius: 4px;
		border: 1px solid $border-color;
		margin-right: 0.25rem;

		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			padding: 0;
		}
	}

	.selectedCheckbox {
		background: $button-primary;
		border: 1px solid $button-primary;
	}
}

.selectedAnswer {
	// outline: 2px solid $button-primary;
	background: $state-hover-selected;

	.bubble {
		height: 0.5rem;
		width: 0.5rem;
		outline: 4px solid $button-primary;
	}
}

.selectedButton {
	outline: 2px solid $button-primary;
}

.inputField {
	@extend .body;
	@include padding(padding, 0.75rem 1rem);
	font-weight: 500;
	width: 100%;
	display: flex;
	align-items: flex-start;

	gap: 0.625rem;
	border-radius: 0.5rem;
	border: none;
	background: $bg-white;

	&:focus {
		outline: 2px solid $button-primary;
	}

	@media screen and (max-width: $md) {
		font-weight: 400;
		padding: 0.5rem;
	}
}

.viewOnly {
	@extend .button;
	width: 25rem;
	max-width: 100%;
	color: $text-primary;
	line-height: 1.5rem;
	display: flex;
	flex-wrap: wrap;
	gap: 0.375rem;

	@media screen and (max-width: $md) {
		transform: none;
		padding-left: 1rem;
		box-sizing: border-box;
	}
}

// GENERAL
.flex {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.spacedFlex {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
}

.column {
	display: flex;
	flex-direction: column;
}

.hideScrollbar {
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
}

.primaryButton {
	@extend .button;
	@include padding(padding, 0.5rem 1rem);
	font-weight: 600;
	position: relative;
	width: fit-content;
	color: $bg-white;
	background: $button-primary;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	border-radius: 0.5rem;
	gap: 0.5rem;

	&.pBtnW100 {
		width: 100%;
	}

	svg {
		min-width: 1rem;
		min-height: 1rem;
		path {
			fill: $bg-white;
		}
	}

	&:disabled {
		cursor: not-allowed;
	}
	&:hover {
		background: $button-hover;
	}
}

.secondaryButton {
	@extend .primaryButton;
	border: 1px solid $border-color;
	background: $bg-white;
	color: $text-secondary;

	svg {
		width: 1.25rem;
		height: 1.25rem;
	}

	svg path {
		fill: $text-secondary;
	}

	&.smallSvg {
		position: relative;

		svg {
			max-width: 1.25rem;
			max-height: 1.25rem;
		}

		.roleDropdown {
			position: absolute;
			background: $bg-white;
			color: $text-secondary;
			border: 1px solid $border-color;
			border-radius: 0.5rem;
			top: 3.125rem;
			box-shadow: 0px 2px 0.375rem 0px rgba(13, 10, 44, 0.16);
			z-index: 1000;
			width: 12.5rem;
			left: 0;

			.option {
				@extend .button;
				@include padding(padding, 0.625rem 1rem);
				font-weight: 600;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background: $bg-white;

				&:hover {
					background: $state-hover;
				}

				&:first-child {
					border-radius: 8px 8px 0 0;
				}

				&:last-child {
					border-radius: 0 0 8px 8px;
				}
			}
		}
	}

	&:hover {
		color: $text-primary;
		background: $state-hover;
		svg path {
			fill: $text-primary;
		}
	}
}

.selectedSecondary {
	background: $bg-grey;
}

.greyButton {
	@extend .secondaryButton;
	background: $state-hover;
	border: none;

	&:hover {
		background: $state-hover-grey;
	}
}

.noBorderButton {
	@extend .secondaryButton;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.selectedNoBorder {
	@extend .greyButton;
	color: $text-primary;
}

.bottomLineNavigation {
	@include padding(padding, 0.5rem);
	display: flex;
	flex-direction: column;
	width: 100%;
	cursor: pointer;

	.flex {
		display: flex;
		width: 100%;
		border-bottom: 1px solid $border-color;
	}

	.navButton {
		all: unset;
		@include padding(padding, 1rem);
		font-size: 0.875rem; // 14px
		font-weight: 500;
		line-height: 1.25rem; // 20px
		letter-spacing: 0;
		color: $text-secondary;
		border-radius: 0.5rem 0.5rem 0 0;

		&:hover {
			background: $state-hover;
			color: $text-primary;
		}
	}

	.selected {
		border-bottom: 1px solid $text-primary;
		color: $text-primary;
		font-weight: 600;
	}

	.separator {
		width: 100%;
		height: 0;
		border-bottom: 1px solid $border-color;
		transform: translateY(-1px);
	}
}

.badge {
	background: $button-primary;
	position: absolute;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 50%;
	top: -2px;
	right: -0.375rem;
}

.search {
	position: relative;
	width: 100%;
	max-width: 31.25rem;
	display: flex;
	flex-direction: column;

	&.searchW100 {
		max-width: 100%;
	}

	.input {
		@extend .kicker;
		@include padding(padding, 0.5rem 1rem);
		display: flex;
		align-items: center;
		min-width: 11.25rem;
		height: 3rem;

		gap: 0.625rem;
		border-radius: 0.5rem;
		border: 1px solid $border-color;
		background: $bg-grey;
		outline: none;

		&::placeholder {
			@extend .body;
			color: $text-placeholder;
		}
	}

	.icon {
		position: absolute;
		top: 50%;
		transform: translate(0.625rem, -50%);
	}

	svg path {
		fill: $text-placeholder;
	}
}

.spinner {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&.spinnerFullPage {
		position: fixed;
		background: $bg-white;
		z-index: 9999;
		top: 0;
	}

	span {
		position: relative;
		width: 1rem;
		height: 1rem;
		border: 2px solid $button-primary;
		border-top: 2px solid $bg-white;
		border-radius: 50%;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.empty {
	@include padding(padding-top, 1rem);
	color: $text-primary;
	font-size: 0.875rem;

	&.techEmpty {
		@include padding(padding, 1rem);
		font-size: 0.75rem;
	}
}

// TABLES
.tableWrapper {
	height: calc(100% - 2.8125rem);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
	margin-top: 2.8125rem;

	@media screen and (max-width: $md) {
		height: 100%;
		margin-top: 0;
	}

	.backArrowContainer {
		@include padding(padding, 0.75rem);
		display: flex;
		align-self: flex-start;
		cursor: pointer;

		.backArrowIcon {
			transform: rotate(90deg);
		}
	}

	.content {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: fit-content;

		.header {
			@include padding(padding, 2rem 0);
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 1rem;
			border-bottom: 1px solid $border-color;

			@media screen and (max-width: $md) {
				padding: 2rem 1rem;
			}

			.title {
				@include padding(padding, 1rem 0);
				color: $text-primary;
				font-size: 2rem;
				line-height: 2rem;
				font-weight: 600;
			}

			.searchContainer {
				display: flex;
				flex-wrap: wrap;
				gap: 1rem;
			}

			.titleCategory {
				color: $text-secondary;
				font-size: 1rem;
				font-weight: 500;

				@media screen and (max-width: $md) {
					font-size: 0.75rem;
				}
			}

			.titleTwo {
				@extend .title;
				@include padding(padding, 0);

				@media screen and (max-width: $md) {
					font-size: 1.5rem;
				}
			}

			.inputContainer {
				border-radius: 0.5rem;

				.inputContainerTitle {
					color: $text-primary;
					font-size: 2rem;
					font-weight: 600;
					outline: none;
					width: -webkit-fill-available;
					border: none;
					border-radius: 0.5rem;

					&:hover {
						background: $state-hover;
					}

					@media screen and (max-width: $md) {
						font-size: 1.5rem;
					}
				}
			}
		}

		.userPopupContainer {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1100;
			overflow: hidden;

			background: rgba(0, 0, 0, 0.5);

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

// TECH
.techNav {
	@include padding(padding, 0 1.25rem);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	height: 3.375rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: $bg-white;
	border-bottom: 1px solid $border-color;

	.title {
		@extend .button;
		font-weight: 600;
		color: $text-primary;
		display: flex;
	}

	.buttonsContainer {
		display: flex;
		gap: 0.625rem;
	}

	.navButton {
		@include padding(padding, 0.75rem);
		background: none;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		cursor: pointer;

		svg {
			width: 1.5rem;
			height: 1.5rem;

			path {
				fill: $text-secondary;
			}
		}
	}

	.close {
		@include padding(padding, 0.75rem);
		svg {
			width: 0.875rem;
			height: 0.875rem;
		}
	}
}
