@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.questionSelector {
	display: flex;
	flex-direction: column;
	height: calc(100dvh - 4.5rem);

	.closeButton {
		@include padding(padding, 1rem 0 2rem 0);
		display: flex;
		flex-direction: row-reverse;

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	h4 {
		font-weight: 600;
	}

	.header {
		@include padding(padding, 1rem 1rem);
		border-bottom: 1px solid $border-color;
	}

	.content {
		height: 100%;
		overflow: auto;

		h5 {
			@include padding(padding, 1rem);
		}

		p {
			margin: 0;
		}

		.question {
			@include padding(padding, 1rem 1rem);
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 1rem;
		}

		.selected {
			background-color: $state-hover-selected;

			.button {
				color: $button-primary;
			}
		}
	}

	.footerNav {
		@include padding(padding, 0 1.5rem);
		border-top: 1px solid $border-color;
		background: $bg-white;
		min-height: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}

		.tabBarItems {
			font-weight: 600;
		}
	}
}

// Progress bar

.progressContainer {
	@include padding(padding, 0 5rem 1rem 0);
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;

	.tabBarItems {
		line-height: 1.25rem;
		white-space: nowrap;
	}

	.progressBarBackground {
		width: 100%;
		min-height: 4px;
		max-height: 4px;
		background-color: #e9eaed;
		border-radius: 4px;
		overflow: hidden;
		position: relative;
	}

	.progressBar {
		height: 100%;
		background-color: $button-primary;
		width: 0%;
		border-radius: 4px;
		transition: width 0.3s ease;
	}

	.flex {
		.tabBarItems {
			font-weight: 600;
		}

		svg {
			width: 1.25rem;
			height: 1.25rem;
		}
	}
}

.smallIcons {
	svg {
		min-width: 1rem;
		max-width: 1rem;
		width: 1rem;
		height: 1rem;
		min-height: 1rem;
		max-height: 1rem;
	}
}

.pxValues {
	width: 320px;
	padding: 0 !important;
	gap: 24px;

	.tabBarItems {
		line-height: normal;
	}

	.progressBarBackground {
		height: 4px;
	}
}

.noPadding {
	@include padding(padding, 0);
	max-width: 16rem;
	width: 16rem;
	min-width: 16rem;
	justify-content: flex-start;

	.progressBarBackground {
		margin-top: 2px;
		height: 3px;
	}
}
