@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.history {
	display: flex;
	flex-direction: column;
	border-left: 1px solid $border-color;
	height: 100%;
	min-width: 22.5rem;
	max-width: 22.5rem;

	.flex {
		@include padding(padding, 1rem 1rem 0 1rem);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	h4 {
		font-weight: 600;
	}

	.noBorderButton {
		height: 2.5rem;
		width: 2.5rem;
		margin-bottom: 1rem;

		svg {
			min-width: 1.5rem;
			min-height: 1.5rem;
		}
	}
}

.historyList {
	@extend .hideScrollbar;
	height: 100%;
	overflow-y: auto;
}

.list {
	@extend .hideScrollbar;
}

.historyItem {
	height: fit-content;
}

.historyInfo {
	@include padding(padding, 1rem);
	display: flex;
	flex-direction: column;
	gap: 0.125rem;
	width: 100%;
}

.date {
	@include padding(padding, 1rem 1rem 0 1rem);
	border-top: 1px solid $border-color;
	color: $text-primary;
	font-weight: 600;
	line-height: 1rem;
}

.content {
	padding: 0.5rem 0;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.title {
	color: $text-secondary;
	line-height: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;

	span:first-child {
		color: $text-primary;
		font-weight: 600;
	}
}

.subTitle {
	color: $text-placeholder;
	font-weight: 500;
	margin-bottom: 0.75rem;
}

//

.changes {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.oldModif {
	display: flex;
	flex-direction: column;

	.modifLabel {
		@extend .body;
		color: $text-secondary;
	}
	.modifContent {
		@extend .body;
		color: $text-secondary;
	}
}

.newModif {
	display: flex;
	flex-direction: column;

	.modifLabel {
		@extend .body;
		color: $text-primary;
	}
	.modifContent {
		@extend .body;
		color: $text-primary;
		font-weight: 500;
	}

	margin-bottom: 0.5rem;
	display: flex;
}

.historyImage {
	width: 100%;
	object-fit: cover;
}

.user {
	@include padding(padding, 0 0.5rem 0 0);
	display: flex;
	align-items: center;
	justify-content: space-between;

	.flex {
		@include padding(padding, 0);
	}

	span {
		@extend .body;
		color: $text-secondary;
	}

	display: flex;
	align-items: center;
	gap: 0.5rem;
	width: 100%;
}

.userName {
	color: $text-secondary;
}
