@import 'src/colors';
@import 'src/typography';
@import 'src/mixins';

.simplePopup {
	background: $bg-white;
	position: absolute;
	border: 1px solid $border-color;
	border-radius: 0.5rem;
	width: 19.375rem; // 310px
	z-index: 1;

	.title {
		@extend .button;
		@include padding(padding, 1rem 1.25rem 0.5rem);
		color: $text-secondary;
		font-size: 0.75rem;
	}

	.popupItem {
		@extend .button;
		@include padding(padding, 0.5rem 1rem);
		color: $text-secondary;
		font-weight: 600;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		cursor: pointer;

		&:hover {
			background: $state-hover;
		}

		&.borderBtm {
			border-bottom: 1px solid $border-color;
		}

		&.removeVerticalPadding {
			@include padding(padding, 0 1rem);
		}

		&.iconAtLast {
			display: flex;
			justify-content: space-between;
		}

		svg {
			height: 1.5rem;
			width: 1.5rem;
		}

		.search {
			@include padding(padding, 0.12rem 0 0);
			width: 100%;
			max-width: 100%;

			.resizableTextarea {
				@extend .button;
				@include padding(padding, 0.813rem 0.94rem);
				color: $text-primary;
				line-height: 1.375rem;
				display: flex;
				z-index: 1;
				width: 100%;
				min-height: 3rem;
				height: 3rem;
				align-items: center;
				border: none;
				outline: 1px solid $border-color;

				border-radius: 0.5rem;
				background: $bg-white;

				resize: none;
				* &:focus {
					outline: 2px solid $button-primary;
				}

				&::-webkit-scrollbar {
					width: 0rem;
					background: transparent;
				}

				&::placeholder {
					color: $text-placeholder;
					font-weight: 400;
				}
			}
		}
	}

	.deleteOption {
		@extend .button;
		@include padding(padding, 0.625rem 1rem);
		color: $red;
		font-weight: 600;
		border-bottom-left-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
		border-top: 1px solid $border-color;
		cursor: pointer;

		&:hover {
			background: $state-hover;
		}
	}
}
