@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.menu {
	@extend .hideScrollbar;
	display: flex;
	flex-direction: column;

	height: 100%;
	min-width: 22.5rem;
	max-width: 22.5rem;
	width: 22.5rem;
	overflow-y: scroll;

	.navigationHeader {
		@include padding(padding, 1rem);
		display: flex;
		margin: 1rem;
		border-radius: 0.5rem;
		gap: 1rem;
		cursor: pointer;

		.lastEdit {
			@extend .button;
			color: $text-secondary;
			display: flex;
			align-items: center;
			gap: 0.5rem;
		}

		&:hover {
			background: $state-hover;
		}

		img {
			width: 2.5rem;
			height: 2.5rem;
			object-fit: cover;
			border-radius: 0.25rem;
		}
	}

	.newResponse {
		@extend .button;
		@include padding(padding, 0 2rem);
		font-weight: 600;
		margin-bottom: 0.75rem;
	}
}

.navButtons {
	display: flex;
	margin: 1rem 1rem 0 1rem;
	border-bottom: 1px solid $border-color;

	button {
		@include padding(padding, 0.5rem);
		border: none;
		transform: translateY(1px);
		background: none;
		cursor: pointer;
		color: $text-secondary;

		&:hover {
			color: $text-primary;
		}
	}

	.selected {
		border-bottom: 1px solid $text-primary;
	}
}

.menuItem {
	@include padding(padding, 0.5rem);
	display: flex;
	align-items: center;
	gap: 1rem;
	// height: 4.625rem;
	height: fit-content;
	border: none;
	background: $bg-white;
	border-radius: 0.5rem;
	margin: 0.25rem 0.5rem;
	cursor: pointer;

	&:hover {
		background: $state-hover;
	}

	.modifiedBy {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 1.75rem;
	}

	.title {
		@extend .button;
		font-weight: 600;
	}

	.column {
		display: flex;
		width: 16rem;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	.playIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2rem;
		height: 2rem;

		svg {
			width: 1.25rem;
			height: 1.25rem;
		}
	}
}

.selected {
	background: $state-hover;
}
