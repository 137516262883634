@import 'src/colors';
@import 'src/typography';
@import 'src/common';

.titleInputContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.simple {
	@extend .title-s;
	@include padding(padding, 1rem);
	outline: 1px solid $border-color;
	border-radius: 0.5rem;
	width: 100%;
	color: $text-primary;
	font-weight: 400;
	border: none;
	resize: none;
	overflow-y: hidden;

	&:focus {
		outline: 1px solid $border-color;
	}
}

.comment {
	@extend .title-s;
	@include padding(padding, 0.5rem 1rem);
	border-radius: 0.5rem;
	width: 100%;
	color: $text-primary;
	font-weight: 400;
	border: none;
	resize: none;
	overflow-y: hidden;
	background: $bg-grey;
}

.grey {
	@extend .simple;
	@include padding(padding, 1rem 3rem 1rem 1rem);
	min-height: 3.25rem;
	background: $bg-grey;
	outline: none !important;

	&:not(:empty) {
		background: $state-hover-selected;
		font-size: 1rem;
		font-weight: 600;
	}
}

.blue {
	@extend .simple;
	width: 100%;
	background: $state-hover;

	&:focus {
		background: $bg-white;
		outline: 2px solid $button-primary;
	}
}

.clearButton {
	all: unset;
	position: absolute;
	transform: translate(-1rem, 2px);

	svg {
		width: 1.25rem;
		height: 1.25rem;
	}
}

.title {
	@include padding(padding, 1rem);
	margin: 2px;
	display: flex;
	width: calc(100% - 4px);
	align-items: center;
	gap: 1rem;

	border-radius: 0.5rem;
	border: none;
	background: $state-active;

	color: $text-primary;
	font-family: Inter;
	font-size: 2.25rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	resize: none;
	* &:focus {
		outline: 2px solid $button-primary;
		border-radius: 0.5rem;
	}
}

.emptyContextInput {
	@extend .kicker;
	@include padding(padding, 0.99rem);
	width: 100%;
	color: $text-placeholder;
	font-weight: 400;
	border-radius: 0.5rem;
	border: none;

	resize: none;
	height: calc(3.8rem + 1px);
}

.contextInput {
	@extend .kicker;
	@include padding(padding, 1rem);
	width: 100%;
	color: $text-placeholder;
	font-weight: 400;
	border: none;
	outline: none;

	resize: none;
	height: 3.8rem;
	overflow-y: hidden;

	&:focus {
		outline: 1px solid $border-color;
		border-radius: 0.5rem;
	}
}
